<template>
  <div id="sport-list">
    <!-- <v-card class="mb-8">
      <v-card-title>
        {{ t('Blocked Times') }}
      </v-card-title>

      <v-row class="px-2 ma-0 pb-2 text-center">
      </v-row>

      <v-fab-transition v-if="hasPermission(134)">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-btn
              color="secondary"
              dark
              absolute
              top
              right
              fab
              class="mb-4 me-3"
              v-on="on"
              @click="onCreate"
            >
              <v-icon>
                {{ icons.mdiPlus }}
              </v-icon>
            </v-btn>
          </template>
          <span>{{ t("tooltip.new") }}</span>
        </v-tooltip>
      </v-fab-transition>
    </v-card> -->

    <v-card>
      <v-card-title>
        {{ t('tooltip.filters') }}
      </v-card-title>

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          md="3"
          sm="12"
        >
          <v-select
            v-model="fieldFilter"
            :label="t('fields.field')"
            :placeholder="t('fields.field')"
            :items="fieldsOptions"
            item-text="name"
            item-value="id"
            outlined
            clearable
            dense
            hide-details
            @change="updateFilter('views-blocked-times-list', 'field', fieldFilter)"
          ></v-select>
        </v-col>

        <!-- <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            placeholder="Search"
            outlined
            dense
            hide-details
          >
          </v-text-field>
        </v-col> -->
      </v-row>

      <v-divider />

      <v-row class="px-2 ma-0 text-center">
        <v-col
          cols="12"
          sm="12"
          md="3"
        >
          <v-text-field
            v-model="searchQuery"
            :placeholder="t('search')"
            outlined
            hide-details
            dense
          >
          </v-text-field>
        </v-col>

        <v-spacer />
        <v-col
          v-if="hasPermission(134)"
          cols="12"
          sm="2"
        >
          <!-- @click="onCreate" -->
          <v-btn
            color="secondary"
            block
            @click="isViewDrawerActive = true"
          >
            <v-icon>
              {{ icons.mdiPlus }}
            </v-icon>
            {{ t('tooltip.add') }}
          </v-btn>
        </v-col>
      </v-row>

      <v-data-table
        :headers="computedTableColumns"
        :items="listTable"
        item-key="id"
        :loading="loading"
        :loading-text="t('table.loading_text')"
        :no-data-text="t('table.no_data')"
        :header-props="{sortByText: t('table.sort_by')}"
        :footer-props="{itemsPerPageText: t('table.item_page'), 'items-per-page-options':[10, 15, 25, 50]}"
        :items-per-page="-1"
        :search.sync="searchQuery"
        :sort-desc.sync="descSort"
        :options.sync="options"
        :server-items-length="totalListTable"
      >
        <!-- blocked_date -->
        <template #[`item.blocked_date`]="{item}">
          {{ formatUSADate(item.blocked_date) }}
        </template>

        <!-- weekdays -->
        <template #[`item.weekdays`]="{item}">
          {{ resolveWeekdaysText(item.weekdays) }}
        </template>

        <!-- status -->
        <template #[`item.status`]="{item}">
          <v-chip
            small
            :color="resolveSportStatusVariant(item.status)"
            :class="`${resolveSportStatusVariant(item.status)}--text`"
            class="v-chip-light-bg font-weight-semibold text-capitalize"
          >
            {{ resolveSportStatusText(item.status) }}
          </v-chip>
        </template>

        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-tooltip
            v-if="hasPermission(133)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="info"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onShow(item)"
              >
                {{ icons.mdiEyeOutline }}
              </v-icon>
            </template>
            <span>Details</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(135)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="success"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onEdit(item)"
              >
                {{ icons.mdiPencilOutline }}
              </v-icon>
            </template>
            <span>Edit</span>
          </v-tooltip>

          <v-tooltip
            v-if="hasPermission(136)"
            bottom
          >
            <template #activator="{ on: tooltip, attrs }">
              <v-icon
                size="20"
                class="me-2"
                color="error"
                v-bind="attrs"
                v-on="{ ...tooltip }"
                @click="onDelete(item.id)"
              >
                {{ icons.mdiDeleteOutline }}
              </v-icon>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>

    <v-navigation-drawer
      v-model="isViewDrawerActive"
      :right="!$vuetify.rtl"
      touchless
      app
      temporary
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '100%'"
    >
      <blocked-times-drawer
        v-model="isViewDrawerActive"
        :is-open="isViewDrawerActive"
        :option="option"
        @refresh="onRefresh"
      />
    </v-navigation-drawer>
  </div>
</template>

<script>
/* eslint-disable import/no-cycle */
/* eslint-disable object-curly-newline */
import { ref, onMounted } from '@vue/composition-api'
import { mdiPlus, mdiDeleteOutline, mdiPencilOutline, mdiEyeOutline } from '@mdi/js'
import { useUtils } from '@core/libs/i18n'
import { useRouter, formatUSADate } from '@core/utils'
import { deleteBlockedTime } from '@api'

// sidebar
import { avatarText } from '@core/utils/filter'
import { error, success } from '@core/utils/toasted'
import Swal from 'sweetalert2'

import BlockedTimesDrawer from './BlockedTimesDrawer.vue'

import useBlockedTimesList from './useBlockedTimesList'

export default {
  components: {
    BlockedTimesDrawer,
  },
  setup() {
    const { t } = useUtils()
    const { router, route } = useRouter()

    const isViewDrawerActive = ref(false)
    const option = ref(1)

    const {
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      loading,
      filters,
      options,
      descSort,
      userData,
      fieldFilter,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,

      fetchBlockedTimes,
      fetchFields,
      resolveSportStatusVariant,
      resolveSportStatusText,
      resolveWeekdaysText,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      getFilterByModule,
    } = useBlockedTimesList()

    const onRefresh = async () => {
      isViewDrawerActive.value = false
      await fetchBlockedTimes()
    }

    const onCreate = () => {
      option.value = 1
      router.push({
        name: 'views-blocked-times-form',
        params: {
          option: 1,
        },
      })
    }

    const onEdit = item => {
      option.value = 3
      router.push({
        name: 'views-blocked-times-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 3,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onShow = item => {
      option.value = 2
      router.push({
        name: 'views-blocked-times-form',
        params: {
          id: item.id,
          dataParams: item,
          option: 2,
          pageParams: options.value,
          filterParams: filters.value,
        },
      })
    }

    const onDelete = async id => {
      Swal.fire({
        title: 'Estas seguro?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Eliminar!',
        cancelButtonText: 'No',
      }).then(async result => {
        if (result.isConfirmed) {
          const response = await deleteBlockedTime(id)
          if (response.ok) {
            success(response.message)
            await fetchBlockedTimes()
          } else {
            error(response.message.text)
          }
        }
      })
    }

    onMounted(async () => {
      const { filterParams, pageParams } = route.value.params
      setOptions(pageParams)
      setFilters(filterParams)

      const filtersStored = getFilterByModule('views-blocked-times-list')
      if (filtersStored.field) fieldFilter.value = filtersStored.field
      if (filtersStored.search) searchQuery.value = filtersStored.search

      await fetchFields()
      await fetchBlockedTimes()
    })

    return {
      isViewDrawerActive,
      option,
      listTable,
      totalListTable,
      computedTableColumns,
      searchQuery,
      loading,
      filters,
      options,
      descSort,
      userData,
      fieldFilter,
      groupsOptions,
      facilitiesOptions,
      fieldsOptions,

      onCreate,
      onEdit,
      onShow,
      onDelete,
      avatarText,
      resolveSportStatusVariant,
      resolveSportStatusText,
      resolveWeekdaysText,
      hasPermission,
      setFilters,
      setOptions,
      updateFilter,
      formatUSADate,
      fetchBlockedTimes,
      onRefresh,

      // i18n
      t,

      // icons
      icons: {
        mdiDeleteOutline,
        mdiPlus,
        mdiEyeOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/sport.scss';
</style>
